<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
		<div class="home">
			<div @click="go('My')">我的主页</div>
			<div>
				< </div>
					<div style="color: #E66617;">我的推广</div>
			</div>
			<div class="off">
				<div class="offline">
					<div>
						<div class="bottom">
							<div class="title">我的资讯</div>
							<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
								@select="handleSelect" text-color="#08243C">
								<el-menu-item index="1">我的资讯</el-menu-item>
								<el-menu-item index="2">我参与的资讯</el-menu-item>
							</el-menu>
							<div class="content" v-show="activeIndex == '1'">
								<div class="postings-list">
									<div class="postings" v-for="item in list">
										<div class="postings-title2" @click="askdetail(item.ask_id)"> {{item.ask_title}}
										</div>
										<div class="img-box flex">
											<div class="el-image">
												<img :src="imageUrl(item.thumb)" class="el-image__inner ">
											</div>
										</div>
										<div class="postings-info flex"><span>回复 {{item.replies}}</span>
											<span> 时间 {{item.add_time}}</span>
										</div>
										<div class="del" @click="click_delask(item.ask_id)">删除资讯</div>
									</div>
								</div>
							</div>
							<div class="content" v-show="activeIndex == '2'">
								<div class="postings-list">
									<div class="postings" v-for="item in list">
										<div class="postings-title2" @click="askdetail(item.ask_id)"> {{item.ask_title}}
										</div>
										<!-- <div class="img-box flex">
											<div class="el-image">
												<img :src="imageUrl(item.thumb)" class="el-image__inner ">
											</div>
										</div> -->
										<div class="postings-info flex"><span>点赞 {{item.click_like}}</span>
											<span>时间 {{item.add_time}}</span>
										</div>
										<div class="del" @click="click_delapply(item.answer_id)">删除留言</div>
									</div>
								</div>
							</div>
						</div>
						<div class="page-box" v-if="count>0">
							<div class="pages">
								<el-pagination @current-change="handleCurrentChange" :page-size="size"
									layout="total, prev, pager, next ,jumper" :total="count" :current-page="page">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
			<info />
			<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
			<foot />
		</div>
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import QRCode from "qrcodejs2";
	import {
		extensionMyPromotion,
		askmylist,
		askmymyapply,
		askmydel,
		askmydelapply,
		askdel,
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				content: '',
				invitation_code: '',
				localhostPath: '',
				message: '',
				activeIndex: "1", //menu 默认显示
				count: 1,
				size: 20,
				page: 1,
				list: ''
			};
		},
		created() {
			let wPath = window.document.location.href;
			let pathName = this.$route.path;
			let pos = wPath.indexOf(pathName);
			this.localhostPath = wPath.substring(0, pos);
			this.getMyPromotion();
			this.getaskmylist()
		},
		methods: {
			onCopy() {
				this.$message({
					message: `复制成功！`,
					type: 'success'
				});
			},
			//我的推广 - 我的推广
			getMyPromotion() {
				extensionMyPromotion().then((res) => {
					console.log(res);
					this.content = res.data.content
					this.invitation_code = res.data.invitation_code
					this.qrcode();
					this.message = this.localhostPath + "/login?invitation_code=" + this.invitation_code
				});
			},
			qrcode() {
				let aaa = "/login?invitation_code=" + this.invitation_code
				console.log(aaa);
				let qrcode = new QRCode("qrcode", {
					width: 200, // 二维码宽度，单位像素
					height: 200, // 二维码高度，单位像素
					text: this.localhostPath + aaa // 生成二维码的链接
				});
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
			getaskmylist() {
				askmylist({
					page: this.page
				}).then((res) => {
					console.log(res);
					this.list = res.data.list;
					this.count = res.data.count
				});
			},
			getaskmymyapply() {
				askmymyapply({
					page: this.page
				}).then((res) => {
					console.log(res);
					this.list = res.data.list;
					this.count = res.data.count
				});
			},
			askdetail(id) {
				this.$router.push({
					path: `/RecruitDetail`,
					query: {
						id: id,
					},
				});
			},
			click_delask(id) {
				var that = this
				askdel({
					id: id
				}).then((res) => {
					that.$message({
						message: "删除成功",
						type: "success",
					});
					this.getaskmylist()
				});
			},
			click_delapply(id) {
				var that = this
				askmydelapply({
					id: id
				}).then((res) => {
					that.$message({
						message: "删除成功",
						type: "success",
					});
					this.getaskmymyapply()
				});
			},
			handleSelect(key, keyPath) {
				this.page = 1;
				this.activeIndex = key;
				if (key == 1) {
					this.getaskmylist()
				} else {
					this.getaskmymyapply()
				}
			},
			handleCurrentChange(val) {
				console.log(val);
				this.page = val;
				if (this.activeIndex == 1) {
					this.getaskmylist()
				} else {
					this.getaskmymyapply()
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;

			div {
				margin-right: 7px;
			}
		}

		.off {
			margin-top: 15px;
			padding: 0 10px;

			.offline {
				padding: 18px 12px;
				font-size: 13px;
				background-color: #FFFFFF;
				color: #000000;

				.reward {
					font-size: 12px;
					color: #333333;
					margin-top: 16px;

					div {
						margin-top: 16px;
					}
				}

				.promotelinks {
					margin-top: 40px;
					color: #08243C;
					font-size: 13px;
				}

				img {
					margin-top: 14px;
					width: 150px;
					height: 150px;
				}
			}
		}
	}

	.bottom {
		background: #ffffff;
		border-radius: 6px;
		padding: 23px 19px 23px 19px;

		.title {
			font-size: 20px;
			font-weight: 400;
			color: #08243c;
			line-height: 36px;
			padding-left: 4px;
		}

		.title::after {
			content: "";
			width: 83px;
			height: 9px;
			background: #e66617;
			display: block;
			margin-top: -15px;
		}

		.content {
			.postings-list {
				width: 100%;
				margin: 0 auto;
				border-radius: 6px;
				background: #fff;
				min-height: 20vh;

				.postings {
					position: relative;
					padding: 17px 0 14px;
					border-bottom: 1px solid #eee;
					cursor: pointer;
				}

				.del {
					position: absolute;
					right: 20px;
					bottom: 0;
					top: 0;
					margin: auto;
					height: max-content;
					background-color: red;
					color: #fff;
					border-radius: 6px;
					padding: 0 20px;
					line-height: 2;
				}

				.postings-title2 {
					font-size: 16px;
					color: #000;
					line-height: 44px;
					display: block;
					cursor: pointer;
				}

				.img-box {
					display: flex;
					flex-wrap: wrap;

					.el-image {
						margin-bottom: 10px;
						margin-right: 10px;
						width: 100px;
						height: 100px;
					}
				}

				.postings-info {
					margin-top: 5px;
					font-size: 12px;
					color: #999;
				}
			}
		}
	}
</style>